






























import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'PredictionScore',
    props: {
        score: {
            type: Number,
            default: 0,
        },
        userDefined: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const colorClass = computed(() => {
            if (props.score >= 4) return 'bg-green-500';
            if (props.score === 3) return 'bg-yellow-500';
            return 'bg-red-500';
        });

        const tooltip = computed(() => {
            switch (props.score) {
                case 1:
                    return 'This prediction is most probably wrong and needs to be corrected';
                case 2:
                    return 'This prediction may be wrong and needs to be corrected';
                case 3:
                    return 'This prediction is probably correct but it needs to be cross-checked';
                case 4:
                    return 'This prediction is very probably correct';
                case 5:
                    return 'This prediction is correct';
                default:
                    return null;
            }
        });

        return { colorClass, tooltip };
    },
});
